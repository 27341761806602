.authInfoContainer{
  @include linear-gradient-left($topbarLeftBgColor,$topbarRightBgColor);
  transition: left $transitionDuration;

  margin:0;
}
.authInfo{
  margin: auto 2em auto auto;
  text-align: right;
  color: white;
  max-width: 450px;
}

.logoContainer{
  text-align: center;
  margin: 1em 0 3em 0;
}

@media (max-width: 1024px) {

  .authInfoContainer{
    @include linear-gradient($topbarLeftBgColor,$topbarRightBgColor);
    transition: top $transitionDuration;

  }

  .authInfo{
    margin: auto;
    text-align: center;
  }
}

.link-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  margin: 0;
  padding: 0;
  color:#007ad9;
  font-size: 1.2em;
}

.link-button:hover,
.link-button:focus {
  text-decoration: none;
}