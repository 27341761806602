@import "./_variables";

$menuDarkBgColorFirst:#273743;
$menuDarkBgColorLast:#42626c;

$topbarLeftBgColor:#273743;
$topbarRightBgColor:#42626c;

$textSecondaryColor: #b1acac;

@import "./sass/_layout";
@import "./_overrides";
@import "./_login";
@import "./_subscription";
@import "./_message";