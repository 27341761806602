.layout-wrapper {

  .ecomscanner{
    @include linear-gradient-left(#f3f4f9, $topbarRightBgColor);
  }

  .layout-topbar {
    padding: 0.7em 1em 0em 1em;

    .layout-menu-button
    {
      padding:0;
      margin:0;

      span{
        font-size: 1.8em;
      }
    }

    .logo{
      //height: 30px;
      width: 148px;
      margin-right: 20px;
    }

    //.layout-topbar-expand
    //{
    //  position: fixed;
    //  padding: .7em 1.5em 0em 1.5em;
    //  color: #ffffff;
    //  z-index: 999;
    //  right: 0;
    //  @include linear-gradient-left($topbarLeftBgColor,$topbarRightBgColor);
    //  @include transition(left $transitionDuration);
    //  text-align: right;
    //}


    .layout-topbar-icons {
      //margin: 0px;
      button {
        margin-left: 0px;
        font-size: 1.2rem;
        border: none;
        .pi{
          font-size: 1.2rem;
        }
        span {
          &.layout-topbar-icon {
            font-size: 1.5em;
            margin-bottom: 1px;
          }
        }
      }

      ul {
        position: absolute;
        border: 1px solid #e0e0e0;
        top: 55px;

        min-width: 130px;
        background-color: #ffffff;
        animation-duration: 0.3s;
        list-style-type: none;
        margin: 0 0 0 -80px;
        padding: 8px 12px;
        border-radius: 3px;
        box-shadow: 0 2px 10px 0 rgba(0, 3, 6, 0.16);
        color: #42626c;
      }

      ul button
      {
        color: #42626c;

      }


      ul:before, ul:after {
        bottom: 100%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        left: 210px;
      }

    }


  }

  .layout-sidebar {
    width: 200px;

    .layout-profile {
      text-align: left;
      margin-top: 10px;
      margin-right: 0px;
      padding: 1em 15px 1em 1em;

      .layout-profile-link {
        margin: 10px 0;
        width: 100%;

        i {
          font-size: 18px;
          margin-left: 0;
        }
      }
    }

  }

  .layout-footer {
    position: fixed;
    bottom: 0;
    width:100%;
    z-index: 102;
    text-align: center;
    padding: 0.5em!important;
  }
}

/* Responsive */
@media (min-width: 1025px) {
  .layout-wrapper {
    &.layout-overlay {
      .layout-sidebar {
        left: -200px;
      }


      &.layout-overlay-sidebar-active {
        .layout-topbar  {

        }
      }
    }

    &.layout-static {
      .layout-sidebar {
        margin-top: 50px;
      }

      .layout-topbar {
        left: 0px;
      }

      .layout-main, .layout-footer {
        margin-left: 200px;
      }

      &.layout-static-sidebar-inactive {
        .layout-sidebar {
          left: -200px;
        }
      }
    }
  }
}

@media (max-width: 1024px) {
  .layout-wrapper {
    .layout-topbar {
      .logo{
        margin-left: 30px;
        margin-right: 0px;
      }
      .layout-menu-button
      {
        margin-left:-180px;
        span{
          font-size: 1.8em;
        }
      }
    }
    &.layout-mobile-sidebar-active {
      .layout-sidebar {
        left: 0px;
      }
    }
  }

}

//panel and table headers
.p-menuitem-text, .p-tabview-title, .p-column-title, .p-panel-title, span.p-sortable-column-icon{
  font-size: 0.8em;
  font-weight: 600;
}


//override button styles
div.p-splitbutton>button.p-button,
//button.p-button
{
  color:#007ad9;
  background-color: transparent;
}
div.p-splitbutton.p-button-secondary>button.p-button,
//button.p-button.p-button-secondary
{
  color:#f4f4f4;
  background-color: transparent;
}
div.p-splitbutton.p-button-success>button.p-button,
//button.p-button.p-button-success
{
  color:#34A835;
  background-color: transparent;
}
div.p-splitbutton.p-button-info>button.p-button,
//button.p-button.p-button-info
{
  color:#007ad9;
  background-color: transparent;
}
div.p-splitbutton.p-button-warning>button.p-button,
//button.p-button.p-button-warning
{
  color:#ffba01;
  background-color: transparent;
}
div.p-splitbutton.p-button-danger>button.p-button,
//button.p-button.p-button-danger
{
  color:#e91224;
  background-color: transparent;
}

//.p-datatable .p-datatable-tbody tr.p-highlight{
//  background-color: rgb(229, 160, 105) !important;
//}

body .p-rating .p-rating-icon{
  color: #007ad9;
}


body .p-link {
  font-size: 14px;
}

.p-tag.p-tag-danger{
  background-color: #ffcdd2;
  color: #c63737;
  font-size: 0.8rem;
}

.p-tag.p-tag-success{
  background-color: #c8e6c9;
  color: #256029;
  font-size: 0.8rem;
}

.dashboard {
  .summary {

    border: 1px solid #dee2e6;
    margin-bottom: 0;
    .detail {
      font-size: 12px;
    }

    .count {
      top: 7px;

      &.green {
        background-color: #20d077;
      }

      &.yellow {
        background-color: #f9c851;
      }

      &.blue {
        background-color: #007be5;
      }

      &.red {
        background-color: #e32340;
      }
    }

  }

  .contacts {
    ul {
      li {
        border-bottom: 0px solid $dividerColor;

        button {
          .title {
            position: absolute;
            left: 0px;
            top: 1px;
            font-size: 18px;
          }

          .subtitle {
            position: absolute;
            left: 0px;
            top: 22px;
            font-size: 12px;
            color: $textSecondaryColor;
          }

          .count {
            position: absolute;
            right: 10px;
            top: 0px;
            font-size: 18px;
          }
        }
        &:last-child {
          border-top: 0px solid $dividerColor;
        }

      }
    }

    .p-panel-content {
      min-height: 200px;
    }

    &.fixed_list {
      .p-panel-content {
        min-height: 520px;
      }
    }

  }
  .activity-list {
    li {
      padding: 16px 2px;
      .count {
        &.green {
          background-color: #20d077;
        }

        &.yellow {
          background-color: #f9c851;
        }

        &.blue {
          background-color: #007be5;
        }

        &.red {
          background-color: #e32340;
        }
      }
      &:first-child {
        border-top: 0px solid $dividerColor;
      }

    }
  }
}

.p-scrollpanel .p-scrollpanel-bar {
  background-color: var(--primary-color);
  opacity: 1;
  transition: background-color .2s;
}

.p-scrollpanel .p-scrollpanel-bar:hover {
  background-color: #007ad9;
}