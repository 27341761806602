.plan-legend{
  color:#607d8b;
  box-sizing: border-box;
  text-align: right;
  margin: 0.5em;

  .plan-row{
    border-bottom: 1px solid #ffffff;
    height: 2.5em;
  }
}

.plan{
  color:#607d8b;
  text-align: center;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  margin: 0.5em;
  border-bottom:2px solid transparent;
  cursor:pointer;

  .plan-row{
    border-bottom: 2px solid #ffffff;
    background: #eceff1;
    height: 2.5em;
  }
}

.plan:hover{
  box-shadow: 0 0 0 3px;
}

.plan-active{
  border-left:2px solid transparent;
  border-right:2px solid transparent;
  border-bottom:2px solid transparent;
}

.plan-header{
  height: 2.5em;
  color: white;
}

.plan-select{
  background: #eceff1;
  height: 2.5em;
}