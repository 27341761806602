/* Place your extensions here to make migration to a new release easier. */
div.kpi-card-logo {
    font-size:2em;
    color:lightgray;
    float:right;
    margin-top: -1.3em;
    text-align: center;
    width:1em;
}


.alignCenter{
    width: 600px;
    max-width: 100%;
    min-width: 250px;
    margin:0 auto;
}

.p-float-label {
    margin-top: 0.5em;
}


.float{
    position:fixed;
    right:43px;
    z-index: 100;
}

.float>button.p-button.p-button-warning{
    background-color: white;
}


@media (max-width: 1024px){
    .campaign-bar {
        width: 2.6em
    }

    .campaignSelect{
        width: 2.6em
    }
}
@media (min-width: 1025px){
    .campaign-bar{
        width:15em
    }

    .campaignSelect{
        width:15em
    }
}



.capitalize {
    text-transform: capitalize;
}


.img-fluid{
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: block;
}
.img-fluid-thumb{
    max-width: 100%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    display: block;
    max-height : 150px;
}
.img-fluid-thumb:hover{
    transform: scale(2);
}




.imageButton{
    padding: 0 !important;
    border: none !important;
    background: none !important;
    width:1.5em !important;
    /*height:1.5em !important;*/
}

.imageButton > .pi {
    font-size: 1.25em;
}

.imageButton .p-button-text {
    padding: 0 !important;
}

.p-datatable {
    overflow-wrap: break-word;
}

.p-datatable .orange {
    background-color: var(--orange-200) !important;
}
.p-datatable .green {
    background-color: var(--teal-100) !important;
}
.p-datatable .darkgreen {
    background-color: var(--green-300) !important;
}
.p-datatable .yellow {
    background-color: var(--orange-100) !important;
}

.admin {
    background-color: var(--pink-300) !important;
}
.basic {
    background-color: var(--orange-300) !important;
}
.professional {
    background-color: var(--green-400) !important;
}
.premium {
    background-color: var(--blue-400) !important;
}
.special {
    background-color: var(--purple-100) !important;
}
.potential {
    background-color: var(--purple-400) !important;
}
.former {
    background-color: var(--teal-300) !important;
}
.drop_shipping {
    background-color: var(--bluegray-100) !important;
}
.deactivated {
    background-color: var(--indigo-50) !important;
}
.scrollToTop {
    background-color: var(--primary-color) !important;
}


.strikethrough-diagonal {
    position: relative;
    color: red;
}
.strikethrough-diagonal:before {
     position: absolute;
     content: '';
     left: 0;
     top: 45%;
     right: 0;
     border-top: 1px solid;
     border-color: inherit;
     -webkit-transform: skewY(-10deg);
     -moz-transform: skewY(-10deg);
     transform: skewY(-10deg);
 }





.p-datatable .p-datatable-tbody > tr > td .p-column-title {
    display: none;
    float: left;
    text-align: left;
    padding: .4rem;
    min-width: 30%;
    margin: -.4em 1em -.4em -.4rem;
    font-weight: bold;
}


.p-row-expanded th>span.p-column-title {
    display: block !important;
}

@media screen and (max-width: 40em) {
    .p-datatable .p-datatable-thead > tr > th,
    .p-datatable .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .p-datatable .p-datatable-tbody > tr > td {
        text-align: right;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .p-datatable .p-datatable-tbody > tr > td .p-column-title {
        display: inline-block;
    }

    .p-datatable .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}

