.speech-wrapper {
    padding: 30px 40px;
    width: 100%;
    /*height: 100%;*/
    overflow: auto;
    overflow-y: scroll;

    .bubble {
        width: 85%;
        height: auto;
        display: block;
        background: antiquewhite;
        border-radius: 4px;
        box-shadow: 2px 8px 5px #000;
        position: relative;
        margin: 0 0 10px;

        .txt {
            /*padding: 8px 14px 8px 14px;*/

            .name {
                font-weight: 600;
                font-size: 12px;
                margin: 0;
                color: #3498db;
                span {
                    font-weight: normal;
                    color: #b3b3b3;
                }
            }
            .name.alt {
                color: #2ecc71;
            }

            .message {
                font-size: 12px;
                margin: 0;
                color: #2b2b2b;
                overflow-wrap: break-word;
                overflow: hidden;

            }

            .timestamp {
                font-size: 11px;
                /*position: absolute;*/
                right: 20px;
                text-transform: uppercase;
                color: #999;
                text-align: right;
            }

            .timestamp.alt {
                text-align: left;
                width: 100%;
                left: 20px;
            }

        }

        .txt.alt {
            text-align: right;
        }

        .bubble-arrow {
            position: absolute;
            width: 0;
            bottom: 42px;
            left: -16px;
            height: 0;
        }

        .bubble-arrow.alt {
            right: -2px;
            bottom: 40px;
            left: auto;
        }

        .bubble-arrow:after {
            content: "";
            position: absolute;
            border: 0 solid transparent;
            border-top: 9px solid antiquewhite;
            border-radius: 0 20px 0;
            width: 15px;
            height: 30px;
            transform: rotate(145deg);
        }

        .bubble-arrow.alt:after {
            transform: rotate(45deg) scaleY(-1);
            border-top: 9px solid lightblue;

        }
    }

    .bubble.alt {
        /*margin: 0 0 25px 0;*/
        float: right;
        background: lightblue;

    }



}